import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import { pages } from '@src/constants/pages';
import Layout from '@src/components/layout';
import Challenge from '@src/components/shared/challenge';
import { events } from '@src/constants/gaEvents';
import { customEvent } from '@src/helpers/gtag';

import gallery7 from '@src/images/gallery7.jpg';
import gallery9 from '@src/images/gallery9.jpg';
import gallery10 from '@src/images/gallery10.jpg';
import gallery11 from '@src/images/gallery11.jpg';
import gallery12 from '@src/images/gallery12.jpg';
import gallery17 from '@src/images/gallery17.jpg';
import gallery18 from '@src/images/gallery18.jpg';
import gallery20 from '@src/images/gallery20.jpg';
import gallery22 from '@src/images/gallery22.jpg';
import gallery23 from '@src/images/gallery23.jpg';
import gallery27 from '@src/images/gallery27.jpg';

import about1 from '@src/images/about1.jpg';
import about2 from '@src/images/about2.jpg';

const About = () => (
  <Layout pageName={pages.about.name} schema={pages.about.schema}>
    <div className="with-bottom-decoration">
      <h1 className="h-center">About us</h1>
      <div className="subheading">
        Find out more informations about us
      </div>
    </div>
    <div className="v-about-intro h-pt-40 h-flex-center">
      <div>
        <p>
          ADCOproto has built a reputation for being a high-quality PCB assembly
          manufacturer for demanding industries, such as: aerospace, automotive, and
          other technology-driven markets.
        </p>
        <p>
          ADCOproto&lsquo;s free instant costing tool for PCB assemblies is
          accurate, transparent and simple to use.
        </p>
        <ul>
          <li>
            We quote parts that are in stock and from multiple distributors.
          </li>
          <li>
            We provide instant feedback on changes needed to finalize your
            BOM.
          </li>
        </ul>
      </div>
      <LazyLoadImage effect="blur" src={gallery9} alt="Assembly machine" />
    </div>

    <div>
      <div className="c-gallery">
        <div className="large">
          <LazyLoadImage effect="blur" src={gallery7} alt="Printed circuit boards" />
        </div>
        <div className="tall">
          <LazyLoadImage effect="blur" src={gallery17} alt="PCB testing" />
        </div>
        <div>
          <LazyLoadImage effect="blur" src={gallery9} alt="PCB mounting" />
        </div>
        <div>
          <LazyLoadImage effect="blur" src={gallery10} alt="Production line" />
        </div>
        <div>
          <LazyLoadImage effect="blur" src={gallery11} alt="Assembly machine" />
        </div>
        <div>
          <LazyLoadImage effect="blur" src={gallery12} alt="Assembly machine" />
        </div>
        <div className="tall">
          <LazyLoadImage effect="blur" src={gallery20} alt="PCB cleaning" />
        </div>
        <div>
          <LazyLoadImage effect="blur" src={gallery23} alt="PCB testing" />
        </div>
        <div className="large">
          <LazyLoadImage effect="blur" src={gallery18} alt="PCB testing" />
        </div>
        <div className="large">
          <LazyLoadImage effect="blur" src={gallery27} alt="PCB testing" />
        </div>
        <div>
          <LazyLoadImage effect="blur" src={gallery22} alt="Assembly machine" />
        </div>
      </div>
    </div>

    <Challenge
      onStartQuotingClick={customEvent(events.aboutStartQuoting())}
      onContactUsClick={customEvent(events.aboutContactUs())}
    />
  </Layout>
);

export default About;

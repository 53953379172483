import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import { getAppUrl } from '@src/helpers/configValues';
import { pages } from '@src/constants/pages';

const Challenge = ({ onStartQuotingClick, onContactUsClick }) => (
  <div className="c-call-to-action">
    <h2 className="h-mb-40">
      Challenge us with your toughest design, we are confident you will be
      satisfied and become a long term customer!
    </h2>
    <div className="c-btn-group">
      <a
        className="c-btn c-btn--primary"
        href={getAppUrl()}
        onClick={onStartQuotingClick}
      >
        Start quoting
      </a>
      <Link
        className="c-btn c-btn--secondary"
        to={pages.contact.path}
        onClick={onContactUsClick}
      >
        Contact us
      </Link>
    </div>
  </div>
);

Challenge.propTypes = {
  onStartQuotingClick: PropTypes.func,
  onContactUsClick: PropTypes.func,
};

export default Challenge;
